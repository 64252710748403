import { render, staticRenderFns } from "./Periods.vue?vue&type=template&id=36b1a002&scoped=true&"
import script from "./Periods.vue?vue&type=script&lang=js&"
export * from "./Periods.vue?vue&type=script&lang=js&"
import style0 from "./Periods.vue?vue&type=style&index=0&id=36b1a002&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b1a002",
  null
  
)

export default component.exports